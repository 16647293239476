import {BorrowingHistoryRecord, Checkout, CheckoutClaim} from '@store/checkouts/checkouts.types';
import {FALLBACK_MATERIAL_TYPE} from '@store/common/common.fallback';

export const FALLBACK_CHECKOUT: Checkout = {
  checkoutId: 0,
  catalogRecordId: 0,
  checkoutBranchName: '',
  checkoutTime: '',
  claimId: 0,
  claimType: '',
  dueDate: '',
  dueDays: 0,
  foundDate: '',
  holdExists: false,
  itemBarcode: '',
  itemMediaType: '',
  itemTitle: '',
  lastRenewal: '',
  patronFirstName: '',
  patronLastName: '',
  status: 'DUE',
  identifier: '',
  imageUrl: '',
}

export const FALLBACK_CHECKOUT_CLAIM: CheckoutClaim = {
  id: 0,
  checkoutId: 0,
  patronId: 0,
  patronComment: '',
  staffComment: '',
  type: 'CLAIMED_RETURNED',
  status: 'UNRESOLVED',
  identifier: '',
  searchAttempts: 0,
  lastSearchDate: '',
  createdByUserId: 0,
  modifiedByUserId: 0,
  active: false,
  created: '',
  modified: '',

  checkout: FALLBACK_CHECKOUT,
  materialType: FALLBACK_MATERIAL_TYPE,
}

export const FALLBACK_BORROWING_HISTORY_RECORD: BorrowingHistoryRecord = {
  id: 0,
  patronId: 0,
  checkoutId: 0,
  circulationItemId: 0,
  catalogRecordId: 0,
  branchId: 0,
  title: '',
  materialTypeId: 0,
  checkoutDate: '',
  checkinDate: '',
  created: '',
  modified: '',
  materialType: FALLBACK_MATERIAL_TYPE,
}
