import {ChangeDetectionStrategy, Component, inject, TrackByFunction} from '@angular/core';
import {ALWAYS, CURRENT_ROW_OBJECT, TableDefinition} from '../../table/table.component.types';
import {CheckoutsSelectors, PatronSelectors} from '@store/store.selectors';
import {CombinedCoverTitleBarcodeComponent} from '../shared/combined-cover-title-barcode/combined-cover-title-barcode.component';
import {Checkout as LegacyCheckout} from '@raven';
import {trackById} from '@store/common/track-by.helpers';
import {PatronCheckoutsActionsComponent} from './patron-checkouts-actions.component';
import {CheckoutService} from './checkout.service';
import {Checkout, CheckoutClaim} from '@store/checkouts/checkouts.types';
import {CheckoutsActions} from '@store/checkouts/checkouts.actions';
import {CheckoutClaimTypeComponent} from './checkout-claim-type.component';
import {Store} from '@ngrx/store';

@Component({
  selector: 'rn-patron-checkouts',
  templateUrl: './patron-checkouts.component.html',
  styleUrls: ['./patron-checkouts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatronCheckoutsComponent {
  public readonly accountStatusSelector = PatronSelectors.AccountStatus;
  public readonly selectors = CheckoutsSelectors.CheckedOut;
  public readonly claimsSelectors = CheckoutsSelectors.Claims;
  public readonly trackCheckoutsById: TrackByFunction<Checkout> = trackById;
  public readonly trackClaimsById: TrackByFunction<CheckoutClaim> = trackById;
  private readonly checkoutService = inject(CheckoutService);
  private readonly store = inject(Store);

  public readonly checkoutsTableDefinition: TableDefinition<Checkout, typeof CheckoutsSelectors.CheckedOut> = {
    defaultSelectorContainer: CheckoutsSelectors.CheckedOut,
    dataSource: 'checkouts',
    columns: [
      {
        type: 'text',
        value: 'dueDateFor',
        header: 'Due Date',
        format: 'DATE_ONLY',
        cssClasses: {
          'text-error': 'overDueFor'
        },
        headerCssClasses: 'table-column-width-small',
        sortable: true,
      },
      {
        type: 'component',
        columnName: 'item',
        componentClass: CombinedCoverTitleBarcodeComponent,
        componentProperties: {
          catalogRecord: 'buildCatalogRecordFor',
          barcode: 'itemBarcodeFor',
        },
        sortable: true,
        sortKey: 'title'
      },
      {
        type: 'text',
        value: 'itemMediaTypeFor',
        header: 'Material Type',
        headerCssClasses: 'table-column-width-small',
        sortable: true,
        sortKey: 'materialType'
      },
      {
        type: 'text',
        value: 'checkoutTimeFor',
        header: 'Check Out Date',
        format: 'DATE_ONLY',
        headerCssClasses: 'table-column-width-small',
        sortable: true,
      },
      {
        type: 'component',
        columnName: 'action',
        headerCssClasses: 'table-column-width-small',
        componentClass: PatronCheckoutsActionsComponent,
        componentProperties: {
          checkout: CURRENT_ROW_OBJECT
        },
      },
    ]
  };

  public readonly claimsTableDefinition: TableDefinition<CheckoutClaim, typeof CheckoutsSelectors.Claims> = {
    defaultSelectorContainer: CheckoutsSelectors.Claims,
    dataSource: 'claims',
    includePaginationControls: false,
    columns: [
      {
        type: 'text',
        value: 'createdFor',
        header: 'Submitted',
        format: 'DATE_ONLY',
        headerCssClasses: 'table-column-width-small'
      },
      {
        type: 'component',
        columnName: 'item',
        componentClass: CombinedCoverTitleBarcodeComponent,
        componentProperties: {
          catalogRecord: 'buildCatalogRecordFor',
          barcode: 'itemBarcodeFor',
        }
      },
      {
        type: 'text',
        value: 'itemMediaTypeFor',
        header: 'Material Type',
      },
      {
        type: 'component',
        columnName: 'status',
        componentClass: CheckoutClaimTypeComponent,
        componentProperties: {
          claimType: 'typeFor'
        },
      },
    ],
    actionIconButtons: [
      {
        iconName: 'text_snippet',
        tooltip: 'Details',
        cssClasses: {'material-icons-outlined': ALWAYS},
        clickActionCreator: CheckoutsActions.showViewClaim
      }
    ]
  };

  renewCheckout(items: Checkout[], canRenew: boolean): void {
    //TODO update checkout actions to use rxjs in order to resolve the checkout and legacy checkout types
    this.checkoutService.showRenewCheckout(items as unknown as LegacyCheckout[], canRenew);
  }

  hasRenewableItems(items: Checkout[], canRenew: boolean): boolean {
    //TODO update checkout actions to use rxjs in order to resolve the checkout and legacy checkout types
    return this.checkoutService.getRenewableItems(items as unknown as LegacyCheckout[], canRenew).length > 0;
  }

  viewClaim(checkoutClaim: CheckoutClaim): void {
    this.store.dispatch(CheckoutsActions.showViewClaim(checkoutClaim));
  }
}
