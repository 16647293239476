import {CoverImageStrategy} from './cover-image-strategy';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class ChiliFreshCoverImageSource implements CoverImageStrategy {
  private BASE_URL = 'https://content.chilifresh.com/?size=M&isbn=';

  getImageUrl(identifier: string): string {
    return this.BASE_URL + identifier;
  }

}
