import {CatalogRecord, MaterialType} from './common.types';

export const FALLBACK_MATERIAL_TYPE: MaterialType = {
  code: '',
  name: '',
  description: '',
}

export const FALLBACK_CATALOG_RECORD: CatalogRecord = {
  id: 0,
  title: '',
  identifier: '',
  identifierType: 'ISBN',
  imageUrl: '',
  materialType: FALLBACK_MATERIAL_TYPE,
}
