<div [rvIsLoading]="!(patronSelector | select)">
  <form [formGroup]="personalInfoForm" novalidate>
    <div class="account-header flex-row-wrap flex-center-space-between flex-gap-30">
      <div class="mat-headline-4 account-title">Account</div>
    </div>
    <div class="account-row flex-row-wrap-col-responsive">
      <div class="account-column">
        <h3 class="account-subtitle">My Information</h3>
        <rv-input-form-field fieldName="firstName" [externalLabel]="true" overrideLabel="First Name"
                             [formGroup]="personalInfoForm"/>
        <rv-input-form-field fieldName="lastName" [externalLabel]="true" overrideLabel="Last Name"
                             [formGroup]="personalInfoForm"/>
        <rv-phone-form-field fieldName="phone" [externalLabel]="true" overrideLabel="Phone Number"
                             [formGroup]="personalInfoForm"/>
        <rv-input-form-field fieldName="email" [externalLabel]="true" [enabled]="false"
                             [formGroup]="personalInfoForm" [includeClearButton]="false"
                             (click)="updateEmail()" onKeyUp="updateEmail()"/>
        <a class="pointer" (click)="updateEmail()" onKeyUp="updateEmail()">Change email</a>
        <br/><br/>
        <rv-input-form-field fieldName="password" [externalLabel]="true" [enabled]="false"
                             [formGroup]="personalInfoForm" [includeClearButton]="false"
                             (click)="updatePassword()" onKeyUp="updatePassword()"/>
        <a class="pointer" (click)="updatePassword()" onKeyUp="updatePassword()">Change password</a>
        <br/><br/>
        <mat-label>PIN&nbsp;&nbsp;
          <em *ngIf="patron.pinGenerated" (click)="$event.stopPropagation()"
              class="ri-information-line" style="font-weight: bold; font-size: 16px"
              matTooltip="Your pin may have been set by a staff member upon account creation, please update/change it here."></em>
        </mat-label>
        <rv-input-form-field fieldName="pin" overrideLabel="" [enabled]="false"
                             [formGroup]="personalInfoForm" [includeClearButton]="false"
                             (click)="updatePin()" onKeyUp="updatePin()"/>
        <a class="pointer" (click)="updatePin()" onKeyUp="updatePin()">Change PIN</a>
      </div>
      <div class="account-column">
        <h3 class="account-subtitle">Address</h3>
        <rv-input-form-field fieldName="address" [externalLabel]="true" [formGroup]="personalInfoForm"
                             overrideLabel="Street Address"/>
        <rv-input-form-field fieldName="address2" [externalLabel]="true" [formGroup]="personalInfoForm"
                             overrideLabel="Apartment, unit, suite, or floor #" errorLabel="This field"/>
        <rv-input-form-field fieldName="city" [externalLabel]="true" [formGroup]="personalInfoForm"
                             overrideLabel="City"/>
        <div class="flex-row flex-gap-12">
          <rv-select-form-field fieldName="state" [externalLabel]="true" overrideLabel="State/Province"
                                [formGroup]="personalInfoForm"
                                [options]="STATE_SELECT" [includeUndefinedOption]="false"/>
          <rv-input-form-field fieldName="zip" [externalLabel]="true" overrideLabel="Zip Code"
                               [formGroup]="personalInfoForm" class="zip-field"/>
        </div>
      </div>
      <div class="account-column">
        <h3 class="account-subtitle">Library Information</h3>
        <rv-input-form-field fieldName="barcode" [externalLabel]="true" overrideLabel="Library Card Bar Code" [enabled]="false"
                             [formGroup]="personalInfoForm" [includeClearButton]="false"/>
        <rv-select-form-field fieldName="defaultBranch" [externalLabel]="true" overrideLabel="Home Branch"
                              [formGroup]="personalInfoForm"
                              [options]="branchSelectors.branchesSelectOptions | select" [includeUndefinedOption]="false"/>
        <mat-checkbox formControlName="optIn">
          Save Reading History
        </mat-checkbox>
        <!--        <mat-label>Theme</mat-label>-->
        <!--        <mat-form-field appearance="outline">-->
        <!--          <mat-select formControlName="theme">-->
        <!--            <mat-option *ngFor="let themeOpt of themeService.allThemes" [value]="themeOpt.value" (click)="themeService.setTheme(themeOpt.value)">-->
        <!--              {{themeOpt.label}}-->
        <!--            </mat-option>-->
        <!--          </mat-select>-->
        <!--        </mat-form-field>-->
      </div>
    </div>
    <div class="clear-for-footer"></div>
    <div class="save-footer mat-elevation-z6">
      <div class="save-footer-content flex-row flex-center-end">
        <div class="spacer"></div>
        <div class="button-row-right">
          <button mat-stroked-button class="ButtonSmallBlack save-button-group button-cancel"
                  (click)="undo()" [disabled]="personalInfoForm.pristine">
            Undo
          </button>
          <button id="saveButton" mat-raised-button color="primary" class="ButtonSmallBlack save-button-group save-button"
                  [disabled]="personalInfoForm.pristine || personalInfoForm.invalid" (click)="submit()">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  </form>
</div>

