import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExpansionPanelComponent} from './expansion-panel/expansion-panel.component';
import {BackgroundAccentComponent} from './background-accent/background-accent.component';
import {BookCarouselComponent} from './book-carousel.component';
import {GridCarouselComponent} from './grid-carousel.component';
import {BookCoverComponent} from '../favorites/book-cover/book-cover.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {MatNativeDateModule, MatOptionModule, MatRippleModule,} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CustomPluralPipe} from './pipes/plural.pipe';
import {MaterialTypeIconComponent} from './material-type-icon/material-type-icon.component';
import {GridItemComponent} from './grid-item/grid-item.component';
import {ListItemComponent} from './list-item/list-item.component';
import {BarcodeComponent} from './barcode.component';
import {
  CarouselComponent,
  CarouselItemDirective,
  ConfirmationDialogComponent,
  IsLoadingDirective,
  LoadingTemplateDirective,
  LoadingWrapperComponent,
  NonBreakingPipe,
  PagerComponent,
  SkeletonTableComponent,
} from '@raven';
import {AccountStatusWarningComponent} from '../alerts/account-status-warning.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import {CharacterMaskPipe} from './pipes/character-mask.pipe';
import {FormatPipe} from './pipes/format.pipe';
import {PhonePipe} from './pipes/phone.pipe';
import {ReplaceEmptyPipe} from './pipes/replace-empty.pipe';
import {YesOrNoPipe} from './pipes/yes-or-no.pipe';
import {StatusChipComponent} from './status-chip/status-chip.component';
import {NamedRouterLinkDirective} from './named-router-link/named-router-link.directive';
import {TruncateDirective} from './truncate/truncate.directive';
import {SelectPipeModule} from './pipes/select-pipe/select-pipe.module';
import {CombinedCoverTitleBarcodeComponent} from './combined-cover-title-barcode/combined-cover-title-barcode.component';
import {PaginationComponent} from '../../table/pagination/pagination.component';
import {MyAccountNavComponent} from '../../_layout/my-account-nav/my-account-nav.component';
import {ImageContainerComponent} from './image-container/image-container.component';

@NgModule({
  declarations: [
    ExpansionPanelComponent,
    BackgroundAccentComponent,
    BookCarouselComponent,
    GridCarouselComponent,
    ImageContainerComponent,
    BookCoverComponent,
    ConfirmationDialogComponent,
    NonBreakingPipe,
    CarouselItemDirective,
    CarouselComponent,
    CustomPluralPipe,
    PagerComponent,
    MaterialTypeIconComponent,
    GridItemComponent,
    ListItemComponent,
    BarcodeComponent,
    LoadingTemplateDirective,
    IsLoadingDirective,
    LoadingWrapperComponent,
    SkeletonTableComponent,
    AccountStatusWarningComponent,
    CharacterMaskPipe,
    FormatPipe,
    NonBreakingPipe,
    PhonePipe,
    CustomPluralPipe,
    ReplaceEmptyPipe,
    YesOrNoPipe,
    StatusChipComponent,
    NamedRouterLinkDirective,
    TruncateDirective,
    CombinedCoverTitleBarcodeComponent,
    PaginationComponent,
    MyAccountNavComponent,
  ],
  exports: [
    ExpansionPanelComponent,
    BackgroundAccentComponent,
    BookCarouselComponent,
    GridCarouselComponent,
    ImageContainerComponent,
    BookCoverComponent,
    ConfirmationDialogComponent,
    CarouselItemDirective,
    CarouselComponent,
    PagerComponent,
    // angular
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    // angular material stuff
    MatNativeDateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxChartsModule,
    // CDKs
    DragDropModule,
    // Forms
    MaterialTypeIconComponent,
    GridItemComponent,
    ListItemComponent,
    BarcodeComponent,
    LoadingTemplateDirective,
    IsLoadingDirective,
    LoadingWrapperComponent,
    SkeletonTableComponent,
    AccountStatusWarningComponent,
    CharacterMaskPipe,
    FormatPipe,
    NonBreakingPipe,
    PhonePipe,
    CustomPluralPipe,
    ReplaceEmptyPipe,
    YesOrNoPipe,
    StatusChipComponent,
    NamedRouterLinkDirective,
    TruncateDirective,
    SelectPipeModule,
    CombinedCoverTitleBarcodeComponent,
    PaginationComponent,
    MyAccountNavComponent,
  ],
  imports: [
    // angular
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    // angular material stuff
    MatNativeDateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxChartsModule,
    // CDKs
    DragDropModule,
    SelectPipeModule,
  ],
  providers: [
    CharacterMaskPipe,
    FormatPipe,
    NonBreakingPipe,
    PhonePipe,
    CustomPluralPipe,
    ReplaceEmptyPipe,
    YesOrNoPipe,
  ],
})
export class SharedModule {
}
