import {DateOnlyIsoString, DateTimeIsoString, MaterialType} from '@store/common/common.types';

export const CHECKOUTS_FEATURE_KEY = 'checkouts';

export interface Checkout {
  readonly checkoutId: number;
  readonly catalogRecordId: number;
  readonly checkoutBranchName: string;
  readonly checkoutTime: DateTimeIsoString;
  readonly claimId: number;
  readonly claimType: string;
  readonly dueDate: DateOnlyIsoString;
  readonly dueDays: number;
  readonly foundDate: string;
  readonly holdExists: boolean;
  readonly itemBarcode: string;
  readonly itemMediaType: string;
  readonly itemTitle: string;
  readonly lastRenewal: DateOnlyIsoString;
  readonly patronFirstName: string;
  readonly patronLastName: string;
  readonly status: CHECKOUT_STATUS;
  readonly identifier: string;
  readonly imageUrl: string;
}

export interface CheckoutClaim {
  readonly id: number;
  readonly checkoutId: number;
  readonly patronId: number;
  readonly patronComment: string;
  readonly staffComment: string;
  readonly type: CheckoutClaimType;
  readonly status: CheckoutClaimStatus;
  readonly identifier: string;
  readonly searchAttempts: number;
  readonly lastSearchDate: DateTimeIsoString;
  readonly createdByUserId: number;
  readonly modifiedByUserId: number;
  readonly active: boolean;
  readonly created: DateTimeIsoString;
  readonly modified: DateTimeIsoString;

  readonly checkout: Checkout;
  readonly materialType: MaterialType;
}

export type CHECKOUT_STATUS = 'OVERDUE' | 'DUE' | 'CHECKED_IN' | 'CLAIMED_RETURNED'
  | 'CLAIMED_NEVER_HAD' | 'CLAIMED_MISSING_PARTS' | 'LOST';

export const CHECKOUT_CLAIM_TYPES = ['CLAIMED_RETURNED', 'CLAIMED_NEVER_HAD', 'CLAIMED_MISSING_PARTS'] as const;
export type CheckoutClaimType = typeof CHECKOUT_CLAIM_TYPES[number];

export const DISPLAYABLE_CHECKOUT_CLAIM_TYPE: Map<CheckoutClaimType, string> = new Map([
  ['CLAIMED_RETURNED', 'Claimed Returned'],
  ['CLAIMED_NEVER_HAD', 'Claimed Never Had'],
  ['CLAIMED_MISSING_PARTS', 'Claimed Missing Parts'],
]);

export type CheckoutClaimStatus = 'UNRESOLVED' | 'WAIVED' | 'CHARGED' | 'FOUND';

export interface BorrowingHistoryRecord {
  readonly id: number;
  readonly patronId: number;
  readonly checkoutId: number;
  readonly circulationItemId: number;
  readonly catalogRecordId: number;
  readonly branchId: number;
  readonly title: string;
  readonly materialTypeId: number;
  readonly checkoutDate: DateTimeIsoString;
  readonly checkinDate: DateTimeIsoString;
  readonly created: DateTimeIsoString;
  readonly modified: DateTimeIsoString;
  readonly materialType: MaterialType;
}
