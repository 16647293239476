<rn-background-accent setHeightFromSelector=".circulation"/>

<ng-container *ngIf="data$ | async as data">
  <ng-container *ngTemplateOutlet="mainContent; context: { data: data }"/>
</ng-container>

<ng-template #mainContent let-data="data">
  <div class="main-content flex-row-wrap flex-center-space-around">
    <div class="left-column">
      <rn-image-container [catalogRecord]="data.item" alt="Book Cover"/>
      <ng-container *ngIf="!mobile">
        <ng-container *ngTemplateOutlet="belowCover"/>
      </ng-container>
    </div>
    <div class="right-column flex-col">
      <div class="title-row flex-row flex-center-space-between">
        <h2 class="title">{{ data.item.title }}</h2>
        <div *ngIf="!mobile" class="item-status">
          <ng-container *ngTemplateOutlet="itemStatus"/>
        </div>
      </div>
      <span class="author">Author: {{ data.item.author ? data.item.author : 'Not Available' }}</span>
      <span class="pickup-location">Pickup Location: {{ data.item.pickupBranchName ? data.item.pickupBranchName : 'Not available' }}</span>
      <span class="isbn">ISBN: {{ data.item.isbn ? data.item.isbn : 'Not Available' }}</span>
      <ng-container *ngIf="mobile">
        <ng-container *ngTemplateOutlet="itemStatus"/>
      </ng-container>
      <div class="circulation">
        <ng-content select="[project=circulationInfo]"></ng-content>
      </div>
      <div class="actions">
        <ng-content select="[project=actions]"></ng-content>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #belowCover>
  <ng-content select="[project=belowCover]"></ng-content>
</ng-template>

<ng-template #itemStatus>
  <ng-content select="[project=statusPill]"></ng-content>
</ng-template>
