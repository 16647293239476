import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CheckoutClaimType, DISPLAYABLE_CHECKOUT_CLAIM_TYPE} from '@store/checkouts/checkouts.types';

@Component({
  selector: 'rn-checkout-claim-type',
  template: `
    <div class="wrapper">
      <div class="status-pending mat-caption">{{ DISPLAYABLE_CHECKOUT_CLAIM_TYPE.get(claimType) }}</div>
    </div>
  `,
  styles: [`
      .wrapper {
          min-width: 10.3rem;
      }

      .status-pending {
          height: 1.75rem;
          border-radius: 1rem;
          align-content: center;
          text-align: center;
          padding: 0.19rem 0.88rem;
          width: fit-content;
          box-sizing: border-box;
          background-color: #FFA726;
      }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutClaimTypeComponent {
  @Input() claimType: CheckoutClaimType;
  public readonly DISPLAYABLE_CHECKOUT_CLAIM_TYPE = DISPLAYABLE_CHECKOUT_CLAIM_TYPE;
}
