<mat-dialog-content class="dialog-content">
  <div class="flex-col flex-gap-12">
    <div class="flex-row flex-start-space-between">
      <div class="mat-headline-4">View Claim</div>
      <mat-icon class="material-icons-outlined" (click)="close()">close</mat-icon>
    </div>
    <rn-checkout-claim-type [claimType]="selectors.typeFor(checkoutClaim) | select"/>
    <div class="item-entry flex-row">
      <div>
        <rn-image-container [catalogRecord]="selectors.buildCatalogRecordFor(checkoutClaim) | select"
                            [alt]="selectors.itemTitleFor(checkoutClaim) | select"
                            maxHeight="image-medium"/>
      </div>
      <div class="item-details flex-col flex-start-center mat-subtitle-2">
        <div class="mat-subtitle-1">{{ selectors.itemTitleFor(checkoutClaim) | select }}</div>
        <div>{{ selectors.itemBarcodeFor(checkoutClaim) | select }}</div>
        <div>{{ selectors.itemMediaTypeFor(checkoutClaim) | select }}</div>
      </div>
    </div>
    <ng-container *ngIf="selectors.hasPatronCommentFor(checkoutClaim) | select">
      <mat-divider class="divider"/>
      <div class="mat-subtitle-1">Claim Details</div>
      <div class="mat-subtitle-2">{{ selectors.patronCommentFor(checkoutClaim) | select }}</div>
    </ng-container>
    <div class="flex-row flex-center-end">
      <div>Submitted: {{ selectors.createdFor(checkoutClaim) | select | format: 'DATE_ONLY' }}</div>
    </div>
    <mat-divider class="divider"/>
    <div class="flex-row flex-center-end">
      <button mat-raised-button color="primary" class="dialog-button confirm-button" (click)="close()">Close</button>
    </div>
  </div>
</mat-dialog-content>
