<div class="index-page">
  <div class="mobile-banner"></div>
  <div class="landing-banner">
    <div class="welcome mat-headline-2">Welcome!</div>
    <h4 class="welcome-list-text">
      <div class="welcome-subtitle">Welcome to the {{ organizationSelectors.name | select }} Library.</div>
      <div>Please login to access your account, place holds and more!</div>
      <div>New to our library? Create an account today!</div>
    </h4>
  </div>
  <div class="login-wrapper">
    <rn-login/>
  </div>
</div>
