<ng-container *ngTemplateOutlet="plainCover; context: { catalogRecord }"/>
<ng-container *ngIf="showFavoriteStatus && favoriteService.favorites | async as favorites">
  <ng-container *ngTemplateOutlet="favoriteToggle; context: { isFavorite: !!favorites[catalogRecord.id] }"/>
</ng-container>

<ng-template #plainCover let-cr="catalogRecord">
  <a routerLink="/catalog-item/{{ catalogRecord.id }}">
    <rn-image-container [catalogRecord]="cr" [alt]="cr.title"/>
  </a>
</ng-template>

<ng-template #favoriteToggle let-isFavorite="isFavorite">
  <div class="favorite-control" (click)="$event.stopPropagation()">
    <mat-icon *ngIf="!isFavorite" (click)="addFavorite()" class="favorite-heart">favorite_border</mat-icon>
    <mat-icon *ngIf="isFavorite" (click)="deleteFavorite()" class="favorite-heart">favorite</mat-icon>
  </div>
</ng-template>
