import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, Input, OnDestroy, ViewChild} from '@angular/core';
import {CatalogRecord, MaterialTypeCode} from '@store/common/common.types';
import {BowkerCoverImageSource} from '../../content-enhancement/bowker-cover-image-source';
import {ChiliFreshCoverImageSource} from '../../content-enhancement/chili-fresh-cover-image-source';
import {CoverImageSource} from '@raven';
import {select} from '@store/store.helpers';
import {OrganizationSelectors} from '@store/store.selectors';
import {Subscription} from 'rxjs';

export type ImageContainerSize = 'image-small' | 'image-medium' | 'image-large' | 'image-extra-large';

@Component({
  selector: 'rn-image-container',
  templateUrl: './image-container.component.html',
  styleUrls: ['./image-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageContainerComponent implements AfterViewInit, OnDestroy {
  @Input() set catalogRecord(catalogRecord: CatalogRecord | undefined) {
    this.imageUrls = this.getImageUrls(catalogRecord);
    if (this.img) {
      this.setStockImageStyles();
    }
  }

  @Input() alt = '';
  @Input() maxHeight: ImageContainerSize = 'image-large';
  @ViewChild('img') img: ElementRef;
  imgElm: HTMLImageElement;
  imageUrls: string[] = [];
  urlIdx = 0;
  imageSource: BowkerCoverImageSource | ChiliFreshCoverImageSource;
  imgSrcSub: Subscription;
  bowkerSource = inject(BowkerCoverImageSource);
  chiliFreshSource = inject(ChiliFreshCoverImageSource);

  constructor(private _change: ChangeDetectorRef) {
    this.imgSrcSub = select(OrganizationSelectors.Auth.coverImageSource)
      .subscribe((source) => {
        this.imageSource = source === CoverImageSource.BOWKER
          ? this.bowkerSource
          : this.chiliFreshSource
      });
  }

  handleLoad = () => {
    if (this.imgElm.naturalWidth === 1 || this.imgElm.naturalHeight === 1) {
      this.nextUrl();
    }
  }

  handleError = () => {
    this.nextUrl();
  }

  ngAfterViewInit() {
    this.imgElm = this.img.nativeElement as HTMLImageElement;
    this.imgElm.addEventListener('load', this.handleLoad);
    this.imgElm.addEventListener('error', this.handleError);
    this.setStockImageStyles();
  }

  ngOnDestroy() {
    this.imgSrcSub.unsubscribe();
    this.imgElm.removeEventListener('load', this.handleLoad);
    this.imgElm.removeEventListener('error', this.handleError);
  }

  getUrl(): string {
    return (!!this.imageUrls && this.imageUrls.length > 0) ? this.imageUrls[this.urlIdx] : '';
  }

  private nextUrl(): void {
    const initialUrl = this.imageUrls[this.urlIdx];
    if (this.urlIdx < (this.imageUrls.length - 1)) {
      this.urlIdx++;
    }
    // iterated until it finds a new url to try or runs out
    while ((this.urlIdx < (this.imageUrls.length - 1)) && this.imageUrls[this.urlIdx] === initialUrl) {
      this.urlIdx++;
    }
    this.setStockImageStyles();
    this._change.markForCheck();
  }

  public getImageUrls(catalogRecord?: CatalogRecord): string[] {
    const imageUrls: string[] = [];
    if (catalogRecord?.identifier) {
      imageUrls.push(this.imageSource.getImageUrl(catalogRecord.identifier));
    }
    if (catalogRecord?.imageUrl) {
      imageUrls.push(catalogRecord.imageUrl)
    }
    imageUrls.push(ImageContainerComponent.getPlaceholderImageUrl(catalogRecord));
    //TODO: eventually dig into itemDetails/marcDetails to calculate full list of item urls
    return imageUrls;
  }

  private static getPlaceholderImageUrl(catalogRecord?: CatalogRecord): string {
    const code = catalogRecord?.materialType?.code;
    switch (code) {
      case MaterialTypeCode.BOOK:
        return '/assets/stock-book-image.jpg';
      case MaterialTypeCode.BLU_RAY:
        return '/assets/stock-cover-art/BluRay-NW.png';
      case MaterialTypeCode.CD_AUDIO:
      case MaterialTypeCode.CD_FOR_COMPUTER:
        return '/assets/stock-cover-art/CD-NW.png';
      case MaterialTypeCode.DVD_AUDIO:
      case MaterialTypeCode.DVD_VIDEO:
        return '/assets/stock-cover-art/DVD-NW.png';
      case MaterialTypeCode.NEWSPAPER:
        return '/assets/stock-cover-art/Newpaper-NW.png';
      case MaterialTypeCode.PERIODICAL:
      case MaterialTypeCode.ARTICLE:
        return '/assets/stock-cover-art/Periodical-NW.png';
      case MaterialTypeCode.SERIAL:
        return '/assets/stock-cover-art/Serial-NW.png';
      default:
        return '/assets/stock-book-image.jpg';
    }
  }

  private setStockImageStyles() {
    // the last image url should be one of our stock images which we can maintain aspect ratio and center vertically
    const img = this.img.nativeElement as HTMLImageElement;
    if (this.urlIdx === this.imageUrls.length - 1) {
      img.setAttribute('class', `stock-image-styles ${this.maxHeight}`);
    } else {
      img.classList.remove('stock-image-styles');
    }
  }
}
