import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CheckoutClaim} from '@store/checkouts/checkouts.types';
import {CheckoutsSelectors} from '@store/store.selectors';

@Component({
  selector: 'rn-claims-details-dialog',
  templateUrl: './claims-details-dialog.component.html',
  styleUrls: ['./claims-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClaimsDetailsDialog {
  public dialogRef: MatDialogRef<ClaimsDetailsDialog> = inject(MatDialogRef);
  public readonly dialogData = inject(MAT_DIALOG_DATA);
  public readonly checkoutClaim: CheckoutClaim = this.dialogData.checkoutClaim;
  public readonly selectors = CheckoutsSelectors.Claims;

  close(): void {
    this.dialogRef.close(false);
  }
}
