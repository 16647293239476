import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {camelCaseToTitleCase} from '@store/transformation.helpers';
import {distinctUntilChanged, Subject} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {isDefined, isUndefined} from '@store/common/typing.helpers';

@Component({
  selector: 'rv-base-form-field',
  template: `
    <!-- Since this label is defined outside the form field, we are responsible for matching its colors to the form control state. -->
    <mat-label *ngIf="externalLabel" class="external-input-label" [class.text-error]="styleExternalLabelAsError">
      <h4 class="form-label" [class.mat-mdc-form-field-error]="styleExternalLabelAsError"
          [class.labelForRequiredField]="isRequired">{{ label }}</h4>
    </mat-label>

    <mat-form-field appearance="outline" floatLabel="always" [subscriptSizing]="subscriptSizing" class="form-field"
                    [hintLabel]="hintLabel">
      <mat-label *ngIf="!externalLabel">
        {{ label }}
      </mat-label>

      <ng-content/>

      <mat-error *ngIf="!customError" hk-field-error fieldLabel="{{ errorLabel || label }}" [field]="control"/>
    </mat-form-field>
  `,
  styles: [`
      .form-label {
          margin-bottom: 0;
      }

      .form-field {
          width: 100%;
      }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseFormFieldComponent implements OnInit, OnChanges {
  @ViewChild(MatFormField, {static: true}) public matFormField: MatFormField;
  @ContentChild(MatFormFieldControl, {static: true}) public formFieldControlToConnect: MatFormFieldControl<unknown>;

  @Input() public fieldName: string;
  @Input() public externalLabel = false;
  @Input() public hintLabel = '';
  @Input() public errorLabel = '';
  @Input() public formGroup: FormGroup;
  @Input() public control: FormControl;  // It's tempting to rename this to "formControl", but that's a reserved name and causes confusing errors.  So don't.
  @Input() public focused = false;
  @Input() public enabled = true;
  @Input() public customError = false;
  @Input() public subscriptSizing: 'fixed' | 'dynamic' = 'dynamic';

  @Input()
  public set overrideLabel(overrideLabel: string | undefined) {
    if (isDefined(overrideLabel)) {
      this.label = overrideLabel;
    } else if (isDefined(this.fieldName)) {
      // If the field name hasn't been set yet (like if this input was set before that one at initialization time), we'll take care of it in ngOnInit().
      this.label = camelCaseToTitleCase(this.fieldName);
    }
  }

  public label: string;
  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  public get styleExternalLabelAsError(): boolean {
    return this.control.touched && !this.control.valid;
  }

  public get styleExternalLabelAsFocused(): boolean {
    return !this.styleExternalLabelAsError && this.focused;
  }

  public ngOnChanges({control, enabled}: SimpleChanges): void {
    /*
      Whenever the latest changes includes "control" or "enabled", we want to enable/disable the control.

      Note that we use the values of the actual input properties when setting, not the "currentValue" from the SimpleChanges object, since we don't know
      which is present in any given invocation of this function.  This way we don't have to worry about the order these inputs happen to get defined.
    */
    if (control || enabled) this.control?.[this.enabled ? 'enable' : 'disable']();
  }

  public ngOnInit(): void {
    this.initializeLabel();
    this.connectProjectedFormControlToFormFieldContainer();
    this.ensureAsyncValidationErrorsAppear();
  }

  public get isRequired(): boolean {
    return this.control.hasValidator(Validators.required);
  }

  private readonly changeDetector: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyed: Subject<void> = new Subject();

  private initializeLabel(): void {
    // If label wasn't already set by overrideLabel, then we default to the field name (at least until the override label is set later).
    if (isUndefined(this.label)) this.label = camelCaseToTitleCase(this.fieldName);
  }

  private connectProjectedFormControlToFormFieldContainer(): void {
    /*
      This is a bit of a hack, since we're updating a private property of MatFormField (which could cause a bug with a future version of Angular Material if
      they no longer make this property available. However, this is the secret to being able to create a MatFormField that works with projected content, which
      is the purpose of this reusable base component.

      This prevents an exception whose message is "mat-form-field must contain a MatFormFieldControl".  Because the control is not a direct child of the form
      field, Angular Material's code can't find it.  So this explicitly makes the connection on the form field's behalf, and voila - no more exception.

      The idea comes from https://ngserve.io/angular-material-tutorial-create-a-reusable-form-field-component/.
    */
    this.matFormField._control = this.formFieldControlToConnect;
  }

  private ensureAsyncValidationErrorsAppear(): void {
    /*
      Without this workaround, async validation errors (such as those that require an API round trip to calculate) don't appear until further user interaction
      occurs.

      We watch the formGroup for status changes, and anytime we get one that isn't PENDING (which is the state while we're waiting for the async validation to
      complete), we mark this component to be checked by the change detector.  This serves our needs, because after the async validation is complete, the
      status will change from PENDING to VALID or INVALID, either of which is a good reason to check for changes so that the form field's error state gets
      updated visually.

      Something like this could be expensive in terms of DOM changes if we're not careful, but we minimize the expense by:
        - listening for these events only when this form field's control uses an async validator.
        - ignoring consecutive change events that report the same status.

      The idea comes from https://github.com/angular/angular/issues/44295#issuecomment-1550095352, and the issue containing the comment describes the issue
      (along with a bunch of amusing replies from the OP when everyone tried to supply workarounds instead of acknowledging the bug!).
    */
    if (!this.control.asyncValidator) return;

    this.formGroup.statusChanges
      .pipe(
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(status => {
        if (status !== 'PENDING') this.changeDetector.markForCheck();
      });
  }
}
