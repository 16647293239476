import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Router} from '@angular/router';
import {debounceTime, map, mergeMap, of, switchMap, tap} from 'rxjs';
import {catchError, filter} from 'rxjs/operators';
import {whenRouterHasNavigatedTo, withLatestFromSelectors} from '@store/common/effects.helpers';
import {PatronSelectors, RouterSelectors} from '@store/store.selectors';
import {RouterActions} from '@store/router/router.actions';
import {NotificationService} from '@raven';
import {OrganizationActions} from '../organization/organization.actions';
import {AuthService} from './auth.service';
import {PatronService} from './patron.service';
import {PatronActions} from './patron.actions';
import {isPopulatedString} from '../common/typing.helpers';

@Injectable({providedIn: 'root'})
export class PatronEffects {

  private readonly actions: Actions = inject(Actions);
  private readonly authService: AuthService = inject(AuthService);
  private readonly notificationService: NotificationService = inject(NotificationService);
  private readonly router: Router = inject(Router);
  private readonly service: PatronService = inject(PatronService);

  public navigateToDashboardFromIndexIfLoggedIn = createEffect(() =>
    this.actions.pipe(
      whenRouterHasNavigatedTo('INDEX'),
      withLatestFromSelectors(PatronSelectors.Auth.hasPatron, RouterSelectors.routeNameMatches('REGISTER_EXISTING_CARD')),
      filter(([, hasPatron, inRegisterExisting]) => hasPatron && !inRegisterExisting),
      map(() => RouterActions.replaceRouteWith('DASHBOARD'))
    )
  );

  public loadAuthTokenOnLoadOrgSuccess = createEffect(() =>
    this.actions.pipe(
      ofType(OrganizationActions.loadAuthOrganizationSuccess),
      switchMap(({organization}) => this.authService.configureTenantId(organization.googlePatronTenantId).pipe(
        map(() => PatronActions.initializeAuthToken()),
        catchError((error: unknown) => of(PatronActions.initializeAuthTokenFailure(error)))
      ))
    )
  );

  initializeAuthToken = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.initializeAuthToken, PatronActions.loginPatronSuccess),
      switchMap(() => this.authService.getAsyncToken().pipe(
        map((response: string) => isPopulatedString(response) ?
          PatronActions.initializeAuthTokenSuccess(response) :
          PatronActions.initializeAuthTokenNoAuth()
        ),
        catchError((error: unknown) => of(PatronActions.initializeAuthTokenFailure(error)))
      ))
    )
  );

  loadAuthPatron = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.initializeAuthTokenSuccess, PatronActions.reloadAuthPatron),
      switchMap(() => this.service.loadAuthPatron().pipe(
        map(response => PatronActions.loadAuthPatronSuccess(response)),
        catchError((error: unknown) => of(PatronActions.loadAuthPatronFailure(error)))
      ))
    )
  );

  loginPatron = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.loginPatron),
      switchMap(({email, password}) => this.authService.login(email, password).pipe(
        tap(() => this.router.navigateByUrl('/catalog')),
        map(() => PatronActions.loginPatronSuccess()),
        catchError((error: unknown) => of(PatronActions.loginPatronFailure(error)))
      ))
    )
  );

  loginError = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.loginPatronFailure),
      tap(() => this.notificationService.showSnackbarError('Login failed.  Please try again.'))
    ), {dispatch: false}
  );

  logoutPatron = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.logoutPatron),
      switchMap(() => this.authService.logout().pipe(
        tap(() => window.location.href = '/')
      ))
    ), {dispatch: false}
  );

  resetPatronPassword = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.resetPatronPassword),
      switchMap(({email}) => this.authService.resetPassword(email).pipe(
        tap(() => this.notificationService.showSnackbarSuccess(`Password reset email sent.`))
      ))
    ), {dispatch: false}
  );

  public validateUniquePatronEmail = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.validatePatronEmailAvailable),
      debounceTime(500),
      switchMap(({email}) => this.service.validatePatronEmailAvailable(email).pipe(
        map(response => PatronActions.validatePatronEmailAvailableSuccess(response)),
        catchError((error: unknown) => of(PatronActions.validatePatronEmailAvailableFailure(error)))
      ))
    )
  );

  changePassword = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.changePassword),
      withLatestFromSelectors(PatronSelectors.Auth.email),
      switchMap(([{oldPassword, newPassword}, email]) => this.authService.reauthenticate(email, oldPassword).pipe(
        switchMap(() => this.authService.changePassword(newPassword).pipe(
          tap(() => this.notificationService.showSnackbarSuccess(`Your password has been updated.`)),
          map(() => PatronActions.changePasswordSuccess()),
          catchError((error: unknown) => of(PatronActions.changePasswordFailure(error)))
        )),
        catchError((error: unknown) => of(PatronActions.changePasswordAuthenticationFailure(error)))
      ))
    )
  );

  changePasswordFailure = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.changePasswordFailure, PatronActions.changePasswordAuthenticationFailure),
      tap(() => this.notificationService.showSnackbarError(`Unable to update password, please try again.`))
    ), {dispatch: false}
  );

  lookupAccount = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.lookupAccount),
      switchMap(({cardNumber, PIN}) => this.service.lookupAccount(cardNumber, PIN).pipe(
        switchMap(({patron, loginToken}) => this.authService.loginWithToken(loginToken).pipe(
          mergeMap(() => [
            PatronActions.loginPatronSuccess(),
            PatronActions.lookupAccountSuccess(cardNumber, PIN, patron),
            PatronActions.completeRegistrationStep('lookupAccount')
          ]),
          catchError((error: unknown) => of(PatronActions.loginPatronFailure(error)))
        )),
        catchError((error: unknown) => of(PatronActions.lookupAccountFailure(error)))
      ))
    )
  );

  lookupAccountFailure = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.lookupAccountFailure),
      tap(() => this.notificationService.showSnackbarError(`Invalid barcode and pin.`)),
      mergeMap(() => [])
    )
  );

  requestVerificationEmail = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.requestVerificationEmail),
      switchMap(({email}) => this.service.requestVerificationEmail(email).pipe(
        mergeMap((verificationUUID) => [
          PatronActions.requestVerificationEmailSuccess(email, verificationUUID),
          PatronActions.completeRegistrationStep('enterEmail')
        ]),
        catchError((error: unknown) => of(PatronActions.requestVerificationEmailFailure(error)))
      ))
    )
  );

  resendVerificationEmail = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.resendVerificationEmail),
      withLatestFromSelectors(PatronSelectors.Registration.email),
      switchMap(([, email]) => this.service.requestVerificationEmail(email).pipe(
        tap(() => this.notificationService.showSnackbarSuccess(`Another email has been sent to ${email}, check your inbox.`)),
        map((verificationUUID) => PatronActions.requestVerificationEmailSuccess(email, verificationUUID)),
        catchError((error: unknown) => of(PatronActions.requestVerificationEmailFailure(error)))
      ))
    )
  );

  verifyEmail = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.verifyEmail),
      withLatestFromSelectors(PatronSelectors.Registration.verificationUUID),
      switchMap(([{code}, verificationUUID]) => this.service.verifyEmail(code, verificationUUID).pipe(
        mergeMap(() => [
          PatronActions.verifyEmailSuccess(),
          PatronActions.completeRegistrationStep('verifyEmail')
        ]),
        catchError((error: unknown) => of(PatronActions.verifyEmailFailure(error)))
      ))
    )
  );

  public loadAccountStatus = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.loadAccountStatus),
      switchMap(() => this.service.loadAccountStatus().pipe(
        map(response => PatronActions.loadAccountStatusSuccess(response)),
        catchError((error: unknown) => of(PatronActions.loadAccountStatusFailure(error)))
      ))
    )
  );

  register = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.register),
      withLatestFromSelectors(PatronSelectors.Registration.email, PatronSelectors.Registration.value),
      switchMap(([, email, value]) => this.service.register(value).pipe(
        mergeMap(() => [
          PatronActions.registerSuccess(),
          PatronActions.completeRegistrationStep('createPassword'),
          PatronActions.registerLoginPatron(email, value.password)
        ]),
        catchError((error: unknown) => of(PatronActions.registerFailure(error)))
      ))
    )
  );

  registerLoginPatron = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.registerLoginPatron),
      switchMap(({email, password}) => this.authService.login(email, password).pipe(
        map(() => PatronActions.loginPatronSuccess()),
        catchError((error: unknown) => of(PatronActions.loginPatronFailure(error)))
      ))
    )
  );

  registerExisting = createEffect(() =>
    this.actions.pipe(
      ofType(PatronActions.registerExisting),
      withLatestFromSelectors(PatronSelectors.Registration.verificationUUID, PatronSelectors.Registration.value),
      switchMap(([, verificationUUID, value]) => this.service.registerExisting(value, verificationUUID).pipe(
        mergeMap(() => [
          PatronActions.registerExistingSuccess(),
          PatronActions.completeRegistrationStep('createPassword')
        ]),
        catchError((error: unknown) => of(PatronActions.registerExistingFailure(error)))
      ))
    )
  );

}
