import {MaterialType} from './material-type';

export class CatalogRecord {
  id: number;
  marcRecord?: string;  // remove this for patron facing
  title: string;
  description?: string;
  author?: string;
  identifier?: string;
  identifierType: IdentifierType;
  imageUrl?: string;
  materialType: MaterialType;
  availability?: CatalogRecordAvailability;
  marcDetails?: MarcDetails;

  patronFavorite?: boolean;
}

export class CatalogRecordAvailability {
  allBranchCount?: number;
  availableCount?: number;
  inTransferCount?: number;
  onHoldCount?: number;
  onOrderCount?: number;
  patronHomeBranchCopyCount?: number;
}

export enum IdentifierType {
  ISBN = 'ISBN',
  ISSN = 'ISSN',
  UPC = 'UPC',
  EAN = 'EAN'
}

export class MarcDetails {
  title?: string;
  subtitle?: string;
  statementOfResponsibility?: string;
  author?: string;
  otherAuthors?: string[];
  summary?: string;
  languageCode?: string;
  physicalDescription?: string;
  publisher?: string;
  publicationDate?: string;
  publicationLocation?: string;
  edition?: string;
  subjects?: string[];
  genres?: string[];
  notes?: string[];
  callNumbers?: { [key: string]: string };
  identifiers?: { [key: string]: string[] };
  controlNumbers?: { [key: string]: string };
  marcRecordStrings?: string[];
  clean: CleanedMarcDetails;
}

export class CleanedMarcDetails {
  publicationDate: string;
}

export interface CarouselDisplay {
  id: number,
  title: string,
  displayOrder: number,
  catalogRecords: CatalogRecord[]
}
