import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {tap} from 'rxjs/operators';
import {CatalogRecord, ConfirmationDialogComponent, FavoriteService} from '@raven';

@Component({
  selector: 'rn-book-cover',
  templateUrl: './book-cover.component.html',
  styles: [`
      :host {
          display: inline-block;
          position: relative;
      }

      .favorite-control {
          position: absolute;
          top: 14px;
          right: 14px;
          background-color: #fff;
          padding: 3px;
          border-radius: 50%;
      }

      .favorite-heart {
          cursor: pointer;
          vertical-align: middle;
          color: #000;
      }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookCoverComponent {
  @Input() catalogRecord: CatalogRecord;
  @Input() showFavoriteStatus = true;

  constructor(private dialog: MatDialog,
              public favoriteService: FavoriteService) {
  }

  confirm(title: string, message: string, callback: () => void): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {title, message},
      })
      .afterClosed()
      .pipe(
        tap((confirm) => {
          if (confirm) {
            callback();
          }
        })
      )
      .subscribe();
  }

  addFavorite(): void {
    this.favoriteService.addFavorite(this.catalogRecord.id).subscribe();
  }

  deleteFavorite(): void {
    this.confirm(
      `Favorites`,
      'Are you sure you want to un-favorite this item?',
      () => {
        this.favoriteService.deleteFavorite(this.catalogRecord.id).subscribe();
      }
    );
  }
}
